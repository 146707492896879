@use "../../../../utilities/scss/variables";
@import "../../../../utilities/scss/global";

.error-body-text-sm-bold {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-4;
  font-size: variables.$font-size-3;
  color: variables.$secondary-red;
  font-style: normal;
}
