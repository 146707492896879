@use "../../../../../utilities/scss/variables";
@import "../../../../../utilities/scss/global";

.border-right {
  border-right: 1px solid variables.$tertiary-gray-2;
}

.client-header-container {
  width: 100%;
  height: 128px;
  padding: 24px;
  background-color: variables.$primary-white;
  border-radius: 10px;
}

.client-name {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-size: variables.$font-size-7;
  color: variables.$primary-black-2;
  font-style: normal;
}

.client-email {
  &.body-text-lg-medium {
    opacity: 0.5;
  }
}

.section-title {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-size: variables.$font-size-6;
  opacity: 0.5;
}

.invested {
  font-family: variables.$primary-font-family;
  font-size: variables.$font-size-5;
  font-style: normal;
  font-weight: variables.$font-weight-2;
  color: variables.$secondary-blue;
}

.distributed {
  font-family: variables.$primary-font-family;
  font-size: variables.$font-size-5;
  font-style: normal;
  font-weight: variables.$font-weight-2;
  color: variables.$secondary-endeavor;
}
