@use "../../../../utilities/scss/variables";
@import "../../../../utilities/scss/global";

.sized-down-enter-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-size: variables.$font-size-5;
  align-self: left;
  vertical-align: top;
  opacity: 0.5;
  line-height: 20px;
  line-height: 82%;
}

.forgot-password {
  font-family: variables.$primary-font-family;
  font-style: normal;
  font-weight: variables.$font-weight-2;
  font-size: variables.$font-size-2;
  line-height: 16px;
  opacity: 0.5;
  color: variables.$primary-black-2;
}

.cover-background {
  background-color: variables.$secondary-cloudy-sky;
  z-index: 1;
}

.foreground-content {
  z-index: 3;
  background-color: variables.$primary-white-lilac;
  height: 100vh;
}
