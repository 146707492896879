@use "../../../../../../utilities/scss/variables";
@import "../../../../../../utilities/scss/global";

.overview-header-text {
  font-family: variables.$primary-font-family;
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
}

.overview-section-text {
  font-family: variables.$primary-font-family;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}

.display-linebreak {
  white-space: pre-line;
}

.bordergrey {
  border: 1px solid variables.$primary-gray;
  margin-left: 0px;
}

.border-left-bottom-grey {
  border-bottom: 1px solid variables.$primary-gray;
  border-left: 1px solid variables.$primary-gray;
  margin-left: 0px;
}

.border-right-top-grey {
  border-top: 1px solid variables.$primary-gray;
  border-right: 1px solid variables.$primary-gray;
  margin-left: 0px;
}

.border-top-grey {
  border-top: 1px solid variables.$primary-gray;
  margin-left: 0px;
}

.border-sides-bottom-grey {
  border-right: 1px solid variables.$primary-gray;
  border-bottom: 1px solid variables.$primary-gray;
  border-left: 1px solid variables.$primary-black-1;
  margin-left: 0px;
}

@media (max-width: 1385px) {
  .default-section-text-muted-500 {
    font-family: variables.$primary-font-family;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    line-height: 16px;
    color: variables.$primary-black-1;
  }

  .default-section-text-500 {
    font-family: variables.$primary-font-family;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    line-height: 16px;
    color: variables.$primary-black-1;
  }

  .default-section-text {
    font-family: variables.$primary-font-family;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    color: variables.$primary-black-1;
  }
}
@media (max-width: 760px) {
  .mobile-padding-left {
    padding-left: 1rem;
  }

  .default-section-text-muted-500 {
    font-family: variables.$primary-font-family;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    color: variables.$primary-black-1;
  }

  .default-section-text-500 {
    font-family: variables.$primary-font-family;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    color: variables.$primary-black-1;
  }

  .default-section-text {
    font-family: variables.$primary-font-family;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: variables.$primary-black-1;
  }
}

@media (max-width: 450px) {
  .mobile-padding-left {
    padding-left: 1rem;
  }

  .mobile-margin-top {
    margin-top: 0.85rem;
  }

  .default-section-text-muted-500 {
    font-family: variables.$primary-font-family;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: variables.$primary-black-1;
  }

  .default-section-text-500 {
    font-family: variables.$primary-font-family;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: variables.$primary-black-1;
  }

  .default-section-text {
    font-family: variables.$primary-font-family;
    font-weight: 400;
    font-style: normal;
    font-size: 11px;
    line-height: 14px;
    color: variables.$primary-black-1;
  }
}

@media (max-width: 400px) {
  .mobile-padding-left {
    padding-left: 0.75rem;
  }

  .mobile-margin-top {
    margin-top: 0.9rem;
  }

  .default-section-text-muted-500 {
    font-family: variables.$primary-font-family;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    line-height: 12px;
    color: variables.$primary-black-1;
  }

  .default-section-text-500 {
    font-family: variables.$primary-font-family;
    font-weight: 500;
    font-style: normal;
    font-size: 11px;
    line-height: 12px;
    color: variables.$primary-black-1;
  }

  .default-section-text {
    font-family: variables.$primary-font-family;
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    line-height: 14px;
    color: variables.$primary-black-1;
  }
}

.overview-section-category-text {
  font-family: variables.$primary-font-family;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}

.hide-bullet-content {
  list-style-type: none;
}
