@use "../../../utilities/scss/variables";
@import "../../../utilities/scss/global";

.body-text-xs-medium-light {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-size: variables.$font-size-2;
  color: variables.$primary-black-2;
  font-style: normal;
  opacity: 0.5;
}
