@use "../../../../utilities/scss/variables";
@import "../../../../utilities/scss/global";

.request-allocation-button {
  background-color: rgba(229, 147, 53, 0.15);
  border: 1px solid variables.$tertiary-orange;
  border-radius: 10px;
  color: variables.$tertiary-orange;
  cursor: pointer;
}
