@use "../../../../../../utilities/scss/variables";
@import "../../../../../../utilities/scss/global";

.document-container {
  overflow-y: auto;
  height: 500px;
  max-height: 500px;
  border: 1px solid variables.$secondary-off-white;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 24px;
}

.checkBox-container {
  width: 24px;
  height: 24px;
}
