@use "../../../utilities/scss/variables";
@import "../../../utilities/scss/global";

.w-90 {
  width: 90%;
}

.page-404-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-4;
  font-style: normal;
  font-size: 60px;
  color: variables.$primary-black-1;
  opacity: 0.5;
}

.content-not-found-text {
  font-family: variables.$primary-font-family;
  font-style: normal;
  font-weight: variables.$font-weight-2;
  font-size: variables.$font-size-5;
  line-height: 30px;
}
