@use "../../../../../utilities/scss/variables";
@import "../../../../../utilities/scss/global";

.fund-detail-header-section {
  background-color: variables.$primary-white;
  border-radius: 6px;
}

.bottom-divider {
  border-bottom: 1px solid variables.$secondary-border;
}

.animated-slow {
  background-color: variables.$tertiary-animation-background;
  background: linear-gradient(
    to right,
    variables.$tertiary-animation-background 5%,
    #eee 20%,
    variables.$tertiary-animation-background 35%
  );
  animation: placeholderShimmer 25s linear 0s infinite normal forwards;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}

.restriction-text {
  font-family: variables.$primary-font-family;
  font-style: normal;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-2;
  color: variables.$primary-blue;
}

@media (min-width: 768px) {
  .bottom-border {
    margin-left: 0px;
    border-right: 1px solid variables.$secondary-border;
  }

  .right-border {
    margin-left: 0px;
    border-right: 1px solid variables.$secondary-border;
  }
}
.bottom-divider {
  border-bottom: 1px solid variables.$secondary-border;
}

.upcoming-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: variables.$high-opacity-orange;
}

.upcoming-stage-text {
  padding: 10px;
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-3;
  color: variables.$tertiary-orange;
}

.currently-fundraising-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: rgba(0, 170, 38, 0.1);
}

.currently-fundraising-stage-text {
  padding: 10px;
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-3;
  color: variables.$secondary-green;
}

.newest-fundraising-complete-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: rgba(100, 66, 207, 0.1);
}

.fundraising-complete-stage-text {
  padding: 10px;
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-3;
  color: variables.$secondary-purple;
}

.newest-closed-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: rgba(29, 95, 195, 0.1);
}

.closed-stage-text {
  padding: 10px;
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-3;
  color: variables.$primary-blue;
}

.newest-past-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: rgba(221, 36, 71, 0.1);
}

.past-stage-text {
  padding: 10px;
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-3;
  color: variables.$secondary-red;
}

.detail-section-title-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-4;
  line-height: variables.$font-size-4;
  color: variables.$primary-black-2;
  opacity: 0.5;
}

@media (min-width: 1200px) {
  .detail-section-title-text {
    font-family: variables.$primary-font-family;
    font-weight: variables.$font-weight-2;
    font-style: normal;
    font-size: variables.$font-size-4;
    line-height: variables.$font-size-4;
    color: variables.$primary-black-2;
    opacity: 0.5;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .detail-section-title-text {
    font-family: variables.$primary-font-family;
    font-weight: variables.$font-weight-2;
    font-style: normal;
    font-size: variables.$font-size-2;
    line-height: variables.$font-size-3;
    color: variables.$primary-black-2;
    opacity: 0.5;
  }
}

.detail-section-value-text {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  color: #262a3d;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .body-text-lg-semibold {
    font-size: variables.$font-size-3;
  }
}
