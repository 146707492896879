@use "../../../../../utilities/scss/variables";
@import "../../../../../utilities/scss/global";

.cancel-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-1;
  font-size: variables.$font-size-3;
  color: variables.$secondary-boulder;
  font-style: normal;
  cursor: pointer;
}
