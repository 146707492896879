@use "./../utilities/scss/variables";
.w-90 {
  width: 90%;
}

.my-custom-input-class {
  cursor: pointer !important;
  display: flex !important;
  text-align: center !important;
  font-family: variables.$primary-font-family !important;
  font-weight: variables.$font-weight-3 !important;
  font-size: variables.$font-size-2 !important;
  color: variables.$tertiary-blue !important;
  border: none !important;
}

.my-custom-input-class:focus {
  outline: none !important;
}

.my-custom-input-text {
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: variables.$primary-font-family, san-serif;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-2;
  color: variables.$tertiary-blue;
}

.right-border-style {
  border-right: 1px solid rgba(226, 230, 237, 0.46);
}

.dropdown-button-container {
  background-color: #ffffff !important;
  border: none !important;
  box-shadow: 0px 6px 18px variables.$box-shadow !important;
  border-radius: 4px !important;
  width: 100%;
  height: 100%;
}

.button-container {
  cursor: pointer !important;
  display: flex !important;
  background-color: variables.$primary-white !important;
  border: none !important;
  box-shadow: 0px 6px 18px variables.$box-shadow !important;
  border-radius: 4px !important;
  width: 100% !important;
}

.ant-btn-style {
  background-color: variables.$primary-white !important;
  border: none !important;
  box-shadow: 0px 6px 18px variables.$box-shadow !important;
  border-radius: 4px !important;
  width: 100% !important;
  height: 100% !important;
}

.dropdown-button-container:focus {
  outline: none !important;
}

.dropdown-text-style {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  font-family: variables.$primary-font-family, san-serif !important;
  font-weight: 500 !important;
  color: variables.$primary-black-1 !important;
  font-style: normal !important;
  font-size: variables.$font-size-2 !important;
  line-height: variables.$font-size-2 !important;
}

.ant-dropdown-menu-item-selected {
  background-color: #e6e8ed !important;
} /*# sourceMappingURL=StrategyFilterStyles.css.map */
