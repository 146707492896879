@use "../../../utilities/scss/variables";
@import "../../../utilities/scss/global";

.custom-triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid variables.$primary-black-2;
  cursor: pointer;
  transition: all 0.25s;
  transform: rotate(90deg);
}

.body-text-md-semibold {
  cursor: pointer;
}
