@use "../../utilities/scss/variables";
@import "../../utilities/scss/global";

.search-box {
margin-right: 50px !important; 
}

.react-bootstrap-table-pagination-list {
  margin-left: -100px !important;
}

.active-page {
    text-decoration: underline;
}

.inactive-page {
    text-decoration: none;
}

.arrow {
    height: 31px; 
    padding-top: 5px;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
}
.page-item {
    color: variables.$primary-gray;
    cursor: pointer; 
    padding-left: 10px;
    padding-right:10px;
    padding-top:5px;
    font-size: variables.$font-size-4;
}
.sample {
  width: 100;
}

.contain {
  width: 90%;
}

.react-bs-table-sizePerPage-dropdown {
    visibility: hidden !important;
}



.spacer {
  height: 16px;
}

.table-row-style:first-child {
  height: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.table-row-style:first-child:hover {
  margin-top: 16px !important;
}

.table-row-style:hover {
  cursor: pointer !important;
  background-color: #f3f5f7 !important;
}

tbody:before {
  content: "@" !important;
  display: block !important;
  line-height: 16px !important;
  text-indent: -99999px !important;
}

.table-row-secondary-information-container {
  border: 2px solid #e2e6ed;
  box-sizing: border-box;
  border-radius: 4px;
}

.table-row-secondary-information-divider {
  border-right: 1px solid #e2e6ed;
}

.table-row-secondary-information-section-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: #262a3d;
}

.table-row-secondary-information-section-value {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: #262a3d;
}

.table-row-style:focus {
  cursor: pointer !important;
  background-color: #e6e8ed !important;
}

.table-row-style-focus {
  cursor: pointer !important;
  background-color: #e6e8ed !important;
}

td {
  border: hidden !important;
}

.table-row-style:first-child {
  cursor: pointer !important;
  margin-top: 16px !important;
}

.table-data-style:first-child {
  padding-left: 15px !important;
}

.start-padding {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  background-color: #f3f5f7 !important;
  border: none !important;
  outline: none !important;
}

.react-bs-container-body tr {
  border-top-style: hidden !important;
}

.table {
  border-top: hidden !important;
  border-bottom: hidden !important;
}

.react-bs-container-header tr {
  border-bottom-style: hidden !important;
}

.start-padding:first-child {
  padding-left: 15px !important;
  border-radius: 10px 0 0 10px !important;
}

.start-padding:last-child {
  border-radius: 0 10px 10px 0 !important;
}

.table-header-text {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: #262a3d !important;
}

.table .thead .tr .th:first-child {
  border-radius: 10px 0 0 10px;
}

.table .thead .tr .th:last-child {
  border-radius: 0 10px 10px 0;
}/*# sourceMappingURL=PortfolioPageStyles.css.map */


.w-98 {
  width: 99%;
}

.table-style {
  background-color: #ffffff;
  border-radius: 10px;
  padding-left: 10px !important;
}

.table-header-style {
  width: 100% !important;
  background-color: #000000;
  border-radius: 10px !important;
}

.table-data-style {
  cursor: pointer !important;
  padding-top: 22px !important;
  padding-bottom: 22px !important;
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: #262a3d !important;
  border-bottom: hidden !important;
}

.spacer {
  height: 16px;
}

.table-row-style:first-child {
  height: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.table-row-style:first-child:hover {
  margin-top: 16px !important;
}

.table-row-style:hover {
  cursor: pointer !important;
  background-color: #f3f5f7 !important;
}

tbody:before {
  content: "@" !important;
  display: block !important;
  line-height: 16px !important;
  text-indent: -99999px !important;
}

.table-row-secondary-information-container {
  border: 2px solid #e2e6ed;
  box-sizing: border-box;
  border-radius: 4px;
}

.table-row-secondary-information-divider {
  border-right: 1px solid #e2e6ed;
}

.table-row-secondary-information-section-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: #262a3d;
}

.table-row-secondary-information-section-value {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: #262a3d;
}

.table-row-style:focus {
  cursor: pointer !important;
  background-color: #e6e8ed !important;
}

.table-row-style-focus {
  cursor: pointer !important;
  background-color: #e6e8ed !important;
}

td {
  border: hidden !important;
}

.table-row-style:first-child {
  cursor: pointer !important;
  margin-top: 16px !important;
}

.table-data-style:first-child {
  padding-left: 15px !important;
}


.react-bs-container-body tr {
  border-top-style: hidden !important;
}

.table {
  border-top: hidden !important;
  border-bottom: hidden !important;
}

