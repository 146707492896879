@use "../../../utilities/scss/variables";
@import "../../../utilities/scss/global";

.header-style {
  background-color: variables.$primary-white;
  box-shadow: variables.$shadow-200;
}

.nav-text {
  cursor: pointer;
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-4;
  opacity: 0.5;
  color: variables.$primary-black-2;
}

.nav-text-selected {
  cursor: pointer;
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-4;
  color: variables.$primary-black-2;
}

.title-bar-active {
  width: 50%;
  height: 2px;
  border-radius: variables.$border-radius-1;
  background-color: variables.$secondary-endeavor;
}
.title-bar-inactive {
  width: 50%;
  height: 2px;
  border-radius: variables.$border-radius-1;
  background-color: inherit;
}
