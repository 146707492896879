@use "../../../../../utilities/scss/variables";
@import "../../../../../utilities/scss/global";

.rounded-edges {
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid variables.$secondary-border;
}

a {
  &.body-text-md-medium {
    color: variables.$primary-blue;
    cursor: pointer;
    &:hover {
      color: variables.$primary-blue-hover;
    }
  }
}
