@use "../../../../utilities/scss/variables";
@import "../../../../utilities/scss/global";

.bottom-divider-border {
  height: 1px;
  width: 100%;
  background-color: variables.$tertiary-gray-2;
}

.border-container {
  border: 1px solid variables.$secondary-border;
  padding: 15px;
  border-radius: 2px;
}
