@use "../../../../../../../utilities/scss/variables";
@import "../../../../../../../utilities/scss/global";

.document-box {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid variables.$secondary-border;
  box-sizing: border-box;
}

.body-text-md-semibold {
  cursor: pointer;
}

.document-box:hover {
  transition: 0.5s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow;
  transform: scale(1.015);
  cursor: pointer;
}

.always-border-right {
  border-right: 1px solid variables.$secondary-border;
}
