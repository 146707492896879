@use "../../../../utilities/scss/variables";
@import "../../../../utilities/scss/global";

.checkBox-border-unselected {
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid variables.$secondary-off-white;
  box-sizing: border-box;
  border-radius: 2px;
}
.checkBox-border-selected {
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid variables.$secondary-endeavor;
  box-sizing: border-box;
  border-radius: 2px;
}
