@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
.w-90 {
  width: 90%;
}

.title-text {
  font-weight: 500;
  font-style: normal;
  font-size: 32px;
  line-height: 20px;
  color: #262a3d;
}

.trouble-text {
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
  color: #262a3d;
  opacity: 0.5;
}

.section-container {
  background-color: #ffffff;
  border-radius: 10px;
}

.section-header-text {
  font-weight: 500;
  font-style: normal;
  font-size: 22px;
  line-height: 22px;
  color: #262a3d;
}

.section-header-box {
  border-bottom: 1px solid #e2e6ed;
}

.sub-section-text {
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
}

.sub-section-text-gray {
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  opacity: 0.5;
  color: #262a3d;
}

.input-name-text {
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #262a3d;
}

.question-field-error-text {
  font-weight: 500;
  font-style: normal;
  font-size: 10px;
  line-height: 10px;
  color: #ff3434;
}

.form-control:focus {
  border-color: #0d47a1 !important;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0) !important;
}

.form-font {
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  color: #262a3d;
}/*# sourceMappingURL=ContactUsStyles.css.map */