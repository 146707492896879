@use "../../../../../../utilities/scss/variables";
@import "../../../../../../utilities/scss/global";

.verification-code-entry-text {
  font-family: variables.$primary-font-family;
  font-style: normal;
  font-size: variables.$font-size-4;
  font-weight: variables.$font-weight-1;
  opacity: 0.5;
}

.check-mail-text {
  font-family: variables.$primary-font-family;
  font-style: normal;
  font-size: variables.$font-size-3;
  font-weight: variables.$font-weight-1;
  opacity: 0.5;
}

.custom-styles {
  --ReactInputVerificationCode-itemWidth: 3.5rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem;
}

@media screen and (max-width: 460px) {
  .custom-styles {
    --ReactInputVerificationCode-itemWidth: 2rem;
    --ReactInputVerificationCode-itemHeight: 3.5rem;
  }
}
.custom-styles .ReactInputVerificationCode__item {
  position: relative;
  color: variables.$primary-black-2;
  font-weight: variables.$font-weight-2;
  font-family: variables.$primary-font-family;
}

.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
}

.custom-styles .ReactInputVerificationCode__item:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 2px;
  background-color: variables.$tertiary-white;
  transition: background-color 0.2s ease-out;
}

.custom-styles .ReactInputVerificationCode__item.is-active:after {
  background-color: variables.$primary-blue;
}

@media screen and (max-width: 991px) {
  .shift-left {
    margin-left: -32px;
  }
}
