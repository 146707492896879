@use "./../../utilities/scss/variables";

.inactive-fund-card {
  width: 100%;
  height: 100%;
  background-color: variables.$primary-white;
  border-radius: 10px;
}
.inactive-fund-card:hover {
  box-shadow: none !important;
  transition: none !important;
  transform: none !important;
}

.fund-card {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: variables.$primary-white;
  border-radius: 10px;
}

.active-fund-card {
  cursor: pointer;
}


.fund-card:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow;
  transform: scale(1.01);
}

.stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: rgba(229, 147, 53, 0.1);
}

.stage-text {
  padding: 10px;
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-size-3;
  font-style: normal;
  font-size: variables.$font-size-3;
  color: variables.$secondary-orange;
}

.fund-card-name-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-size-3;
  font-style: normal;
  font-size: variables.$font-size-7;
  line-height: 24px;
  color: variables.$primary-black-1
}

.fund-card-firm-name-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-size-3;
  font-style: normal;
  font-size: variables.$font-size-7;
  line-height: 20px;
  opacity: 0.5;
  color: #000000;
}

.topBottomBorder {
  border-top: 1px solid variables.$secondary-border;
  border-bottom: 1px solid variables.$secondary-border;
}

.investment-header-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-size-3;
  font-style: normal;
  font-size: variables.$font-size-3;
  line-height: 20px;
  color: variables.$primary-black-1;
  opacity: 0.5;
}

.investment-value-text {
  font-family: variables.$primary-font-family;
  font-weight: 600;
  font-style: normal;
  font-size: 22px;
  line-height: 20px;
  color: variables.$primary-black-1;
}

.allocation-left-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-size-3;
  font-style: normal;
  font-size: variables.$font-size-3;
  line-height: 14px;
  color: #000000;
}

.progressBar-height {
  height: 16px;
} /*# sourceMappingURL=FundCardStyles.css.map */

.projection-header-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-size-3;
  font-style: normal;
  font-size: variables.$font-size-3;
  line-height: 14px;
  opacity: 0.5;
}
