@use "../../../../utilities/scss/variables";
@import "../../../../utilities/scss/global";

.search-bar-container {
  background-color: variables.$tertiary-gray;
  border: 1px solid variables.$tertiary-border-gray;
  border-radius: 10px;
  height: 48px;
  align-items: flex-start;
  flex-direction: row;
  padding: 16px;
}

.search-input {
  margin-top: variables.$font-size-4;
  margin-bottom: variables.$font-size-4;
  font-family: variables.$primary-font-family;
  width: 100%;
  color: variables.$primary-black-2;
  font-size: variables.$font-size-2;
  font-weight: variables.$font-weight-2;
  background-color: transparent;
  border: none;
}
