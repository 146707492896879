@use "../../../../../../utilities/scss/variables";
@import "../../../../../../utilities/scss/global";

.investor-restrictions-value-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  color: variables.$primary-black-2;
  font-size: variables.$font-size-7;
}

.structure {
  &.body-text-md-semibold {
    opacity: 0.5;
  }
}

.tooltip-inner {
  max-width: 500px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: left;
  background-color: variables.$primary-black-1;
  border-radius: 0.25rem;
  padding: 18px;
  font-family: variables.$primary-font-family;
  opacity: 1;
}

.tooltip.show {
  opacity: 1;
}
