@use "../../utilities/scss/variables";
@import "../../utilities/scss/global";

.search-box {
  display: flex;
  float: left;
  position: relative;
  align-items: center;
  flex-direction: row;
  background-color: #ffffff;
  width: 100%;
  height: 48px;
  box-shadow: 0px 6px 18px variables.$box-shadow;
  border-radius: 4px;
}

.magnifier-container {
  float: left;
  margin-left: 17px;
  margin-right: 10px;
  width: 16px;
  height: 16px;
}
.search-input {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-2;
  color: variables.$primary-black-2;
  float: left;
  border: none;
  outline: none;
  cursor: default;
  height: 100%;
  width: 90%;
}
