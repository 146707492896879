@use "../../../../../../utilities/scss/variables";
@import "../../../../../../utilities/scss/global";

.advisor-selection-table {
  .selected-row {
    background-color: var(--selected-row-bg);
  }

  .badge-count {
    display: inline-flex;
    align-items: center;
    padding: 2px 8px;
    border-radius: 12px;
    background-color: var(--badge-bg);
    color: var(--badge-text);
    font-size: 12px;
    font-weight: 500;
  }
}

.empty-state-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: var(--empty-state-bg);
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.empty-state-text {
  color: var(--text-secondary);
  font-size: 14px;
}

.custom-table-container {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb;
  overflow: hidden;

  .custom-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    th {
      background: #f8fafc;
      padding: 16px 24px;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      color: #475569;
      border-bottom: 1px solid #e2e8f0;
    }

    td {
      padding: 16px 24px;
      border-bottom: 1px solid #e2e8f0;
      vertical-align: middle;
      font-size: 14px;
      color: #1f2937;
    }

    tr:last-child td {
      border-bottom: none;
    }

    tr {
      cursor: pointer;
      transition: background-color 0.15s ease;

      // Prevent checkbox click from triggering row selection
      .checkbox-column {
        position: relative;
        z-index: 1;

        * {
          cursor: pointer;
        }
      }

      &.selected {
        background-color: rgba(59, 130, 246, 0.04);
        td {
          color: #2563eb;
        }
      }

      &:hover:not(.selected) {
        background-color: #f8fafc;
      }

      // Add focus state for keyboard navigation
      &:focus {
        outline: none;
        box-shadow: inset 0 0 0 2px #2563eb;
      }
    }
  }

  .checkbox-column {
    width: 48px;
    text-align: center;
  }

  .custom-checkbox {
    position: relative;
    display: inline-block;

    &-input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked + .custom-checkbox-label {
        background-color: #2563eb;
        border-color: #2563eb;

        &:after {
          opacity: 1;
        }
      }

      &:focus + .custom-checkbox-label {
        box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.2);
      }
    }

    &-label {
      display: inline-block;
      width: 18px;
      height: 18px;
      background-color: #fff;
      border: 2px solid #cbd5e1;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.15s ease;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 0;
        transition: opacity 0.15s ease;
      }

      &:hover {
        border-color: #2563eb;
      }
    }
  }

  .advisor-name {
    display: flex;
    align-items: center;
    gap: 12px;

    .avatar {
      width: 32px;
      height: 32px;
      background: #f1f5f9;
      border: 1px solid #e2e8f0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      font-weight: 500;
      color: #475569;
    }

    .name-details {
      .full-name {
        font-weight: 500;
        color: #1f2937;
      }
    }
  }

  .association-badge {
    display: inline-flex;
    align-items: center;
    padding: 6px 12px;
    background: #f1f5f9;
    border: 1px solid #e2e8f0;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 500;
    color: #475569;
    transition: all 0.2s ease;

    &:hover {
      background: #e2e8f0;
    }
  }
}

.warning-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.warning-modal {
  background: white;
  border-radius: 12px;
  padding: 32px;
  width: 100%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);

  &-header {
    margin-bottom: 16px;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: #1f2937;
      margin: 0;
    }
  }

  &-content {
    margin-bottom: 24px;
    color: #4b5563;
    font-size: 14px;
    line-height: 1.5;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    button {
      padding: 8px 16px;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 500;
      transition: all 0.2s ease;

      &.cancel {
        background: #f3f4f6;
        color: #4b5563;
        border: 1px solid #e5e7eb;

        &:hover {
          background: #e5e7eb;
        }
      }

      &.proceed {
        background: #2563eb;
        color: white;
        border: 1px solid transparent;

        &:hover {
          background: #1d4ed8;
        }
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }

      .button-content {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .spinner {
        width: 16px;
        height: 16px;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        border-top-color: #fff;
        animation: spin 1s linear infinite;
      }
    }
  }
}

.warning-message-container {
  .warning-primary {
    margin-bottom: 16px;
    color: #1f2937;
    line-height: 1.5;
  }

  .warning-note {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #f8fafc;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    padding: 12px;

    .info-icon {
      color: #3b82f6;
      font-size: 16px;
    }

    span {
      color: #64748b;
      font-size: 13px;
      line-height: 1.5;
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
