@use "../../../../utilities/scss/variables";
@import "../../../../utilities/scss/global";

textarea:focus,
input:focus {
  outline: none;
}
*:focus {
  outline: none;
}
