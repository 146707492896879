.container-box {
    background-color: #ffffff;
    border-radius: 10px;
  }
  
  .bottom {
    border-bottom: 1px solid #e2e6ed;
  }
  
  .contain-content {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
  }
  
  .right-border {
    border-right: 1px solid #e2e6ed;
  }/*# sourceMappingURL=VerticalTabsStyles.css.map */