@use "../../utilities/scss/variables";
@import "../../utilities/scss/global";

.profile-container {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
}

.cancel-text {
  cursor: pointer;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  color: #92949d;
}

.selection-1 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  border: 1px solid #dadde1;
  box-sizing: border-box;
  border-radius: 6px;
}

.selection-1-selected {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  border: 1px solid variables.$secondary-endeavor;
  box-sizing: border-box;
  border-radius: 6px;
}

.selection-1-text {
  cursor: pointer;
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  opacity: 0.75;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
}

.selection-2 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  border: 1px solid #dadde1;
  box-sizing: border-box;
  border-radius: 6px;
}

.selection-2-selected {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  border: 1px solid variables.$secondary-endeavor;
  box-sizing: border-box;
  border-radius: 6px;
}

.selection-2-text {
  cursor: pointer;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  opacity: 0.75;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
}

.fund-card:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.3s box-shadow;
  transform: scale(1.01);
}

.rbt-input-main .form-control .rbt-input {
  border: 1px solid #dadde1 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  color: #262a3d !important;
}

.rbt-input-hint {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  color: #262a3d !important;
  opacity: 0.5 !important;
}

.stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: rgba(229, 147, 53, 0.1);
}

.stage-text {
  padding: 10px;
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #e59335;
}


.topBottomBorder {
  border-top: 1px solid #e2e6ed;
  border-bottom: 1px solid #e2e6ed;
}


.progressBar-height {
  height: 16px;
}



.reset-password-subtext {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #262a3d;
  opacity: 0.5;
}

.form-field-title {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  color: #262a3d;
}

.Verification-code-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 72px;
  margin-bottom: 32px;
}

.code-container {
  display: flex;
  width: 350px;
  height: 72px;
}

.character {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  font-family: "Montserrat";
  font-size: 24px;
  color: variables.$secondary-endeavor;
  background-color: white;
  border: 1px solid #dadde1;
  box-sizing: border-box;
  border-radius: 6px;
  margin-left: 17.6px;
}

.character:first-child {
  margin-left: 0;
}

.character--selected {
  border: 1px solid variables.$secondary-endeavor;
}

.character--inactive {
  background-color: white;
}/*# sourceMappingURL=ProfilePasswordStyles.css.map */