@use "../../../../utilities/scss/variables";
@import "../../../../utilities/scss/global";

button {
  background: transparent;
  border: none;
}

.active-indicator {
  border-radius: 4px;
  background-color: variables.$primary-blue;
  height: 2px;
}

.inactive-indicator {
  border-radius: 4px;
  height: 2px;
}
