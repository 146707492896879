@use "../../../../utilities/scss/variables";
@import "../../../../utilities/scss/global";

.svg {
  display: block;
  margin: 20px auto;
  max-width: 100%;
}

.svg-circle-bg {
  fill: none;
}

.svg-circle {
  fill: none;
}

.svg-circle-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-style: normal;
  font-size: 1.5rem;
  text-anchor: middle;
  alignment-baseline: middle;
  fill: variables.$primary-blue;
}
.red-text {
  fill: variables.$secondary-red;
}
