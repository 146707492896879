@use "./../../utilities/scss/variables";

.InvestPage-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding-bottom: 5%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: variables.$primary-white-lilac;
}

.InvestPage-container::-webkit-scrollbar {
  display: none;
}

.contain {
  width: 90%;
}

.filter-border {
  background-color: variables.$primary-white;
  box-shadow: 0px 6px 18px variables.$box-shadow; 
  border-radius: 4px;
  font-style: normal;
  font-weight: variables.$font-weight-2;
  min-height: 48px;
  font-size: variables.$font-size-2;
  color: variables.$tertiary-blue;
}

.content-container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 49px;
  margin-right: 49px;
  width: 90%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: variables.$primary-white-lilac;
}

.container-container::-webkit-scrollbar {
  display: none;
}

.title-container {
  float: left;
  width: 100%;
}

.title-text {
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-9;
  line-height: 20px;
  color: variables.$primary-black-1;
}

.subtitle-container {
  margin-top: 19px;
  width: 100%;
}

.subtitle-text {
  margin-top: 19px;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-6;
  line-height: 20px;
  color: variables.$primary-black-1;
  opacity: 0.5;
}

.filter-row {
  display: flex;
  justify-content: bottom;
  margin-top: 24px;
  margin-bottom: 32px;
  max-height: 48px;
  width: 100%;
}

.page-content-container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
}

.header-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 72px;
}

.spacer {
  float: left;
  width: 2%;
}

.filter-spacer {
  float: left;
  width: 20px;
}

.stage-container {
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px 0px;
  width: 88px;
  min-height: 48px;
  height: 48px;
  box-shadow: 0px 6px 18px variables.$box-shadow;
  border-radius: 4px;
  background-color: variables.$primary-white;
}

.minInvestment-container {
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px 0px;
  width: 153px;
  min-height: 48px;
  height: 48px;
  box-shadow: 0px 6px 18px variables.$box-shadow;
  border-radius: 4px;
  background-color: variables.$primary-white;
}

.filter-text {
  cursor: default;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-2;
  line-height: 14.63px;
  color: variables.$tertiary-blue;
}

.image-container {
  position: relative;
  padding-left: 5px;
}

.image-container-rotated {
  position: relative;
  padding-right: 5px;
  transform: rotate(180deg);
}

.stage-dropdown {
  position: relative;
  background-color: variables.$primary-white;
  margin-top: 10px;
  z-index: 2;
  width: 190px;
  height: 225px;
  box-shadow: variables.$shadow-600; 
  border-radius: 4px;
}

.stage-dropdown-hidden {
  display: none;
}

.stage-menu-container {
  float: left;
  display: flex;
  max-width: 88px;
  align-items: right;
  justify-content: right;
  flex-direction: column;
}

.minInvestment-menu-container {
  float: left;
  display: flex;
  max-width: 153px;
  align-items: right;
  justify-content: right;
  flex-direction: column;
}

.allocation-menu-container {
  float: left;
  display: flex;
  max-width: 142px;
  align-items: right;
  justify-content: right;
  flex-direction: column;
}

.allocation-container {
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 142px;
  min-height: 48px;
  height: 48px;
  box-shadow: 0px 6px 18px variables.$box-shadow;
  border-radius: 4px;
  background-color: variables.$primary-white;
}

.dropdown-content {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 45px;
  width: 95%;
  margin-left: 5px;
  border-bottom: 1px solid rgba(148, 153, 187, 0.2);
}

.lastDropdown-content {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 45px;
  width: 100%;
  margin-left: 5px;
}

.checkBox-container {
  float: left;
  width: 12px;
  height: 12px;
  margin-left: 5px;
}

.checkBox-border {
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid variables.$secondary-endeavor;
  box-sizing: border-box;
  border-radius: 2px;
}

.text-container {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
}

.text-label {
  font-family: "Inter";
  font-weight: normal;
  font-style: normal;
  font-size: variables.$font-size-3;
  line-height: 17px;
  color: variables.$primary-black-1;
}

.w-10 {
  width: 5%;
}

.boxLook {
  min-height: 100px;
  width: 100%;
  background-color: variables.$primary-black-1;
}

.checkBox-box {
  height: 15px;
  width: 15px;
}

.fund-container {
  float: left;
  display: flex;
  width: 100%;
  min-height: 50px;
  background-color: variables.$primary-white;
  border-radius: 4px;
}

.fund-container-grid {
  float: left;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto, 5%);
  grid-row-gap: 1em;
  grid-column-gap: 1em;
}