@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.add-account-page-title-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #262a3d;
}

.btn-class {
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 16px !important;
  background-color: #0d47a1 !important;
  color: #ffffff !important;
  border-radius: 4px !important;
}

.btn-class:hover {
  background-color: #0e3690 !important;
}

.btn-class:focus,
.btn-class:active {
  outline: none !important;
  box-shadow: none !important;
}
