@use "./../..//utilities/scss/variables";

.tab-text {
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: variables.$primary-black-1;
  background-color: inherit;
  border: none;
}

.active-indicator {
  border-radius: 4px;
  background-color: variables.$secondary-endeavor;
  height: 2px;
}

.inactive-indicator {
  border-radius: 4px;
  height: 2px;
}

.fillContent-selected {
  cursor: pointer;
  background-color: #e6e8ed;
}

.fillContent-unselected {
  cursor: pointer;
  background-color: none;
}

.fillContent-unselected:hover {
  cursor: pointer;
  background-color: #e6e8ed;
}

.section-label {
  cursor: pointer;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 19.5px;
  color: variables.$primary-black-1;
}/*# sourceMappingURL=VerticalTabTitleStyles.css.map */