/*
Font Families
*/
$primary-font-family: Roboto;

/*
Font Weights
*/
$font-weight-1: 400;
$font-weight-2: 500;
$font-weight-3: 600;
$font-weight-4: 700;

/*
Font sizes
*/
$font-size-1: 10px;
$font-size-2: 12px;
$font-size-3: 14px;
$font-size-4: 16px;
$font-size-5: 18px;
$font-size-6: 20px;
$font-size-7: 24px;
$font-size-8: 28px;
$font-size-9: 32px;
$font-size-10: 40px;

/*
Colors
*/
$primary-blue: #1d5fc3;
$primary-blue-hover: #0b3da1;
$primary-black-1: #262a3d;
$primary-gray: #70727a;
$primary-black-2: #333333;
$primary-white-lilac: #f7f7fa;
$primary-white: #ffffff;
$secondary-porcelain: #f3f5f7;
$secondary-green: #00aa26;
$secondary-orange: #e59335;
$secondary-blue: #0091ea;
$secondary-purple: #6442cf;
$secondary-red: #dd2447;
$secondary-boulder: #6f6f6f;
$secondary-endeavor: #0d47a1;
$secondary-swiss-coffee: #d3d3d3;
$secondary-border: #e2e6ed;
$secondary-cloudy-sky: #f0f4fd;
$secondary-off-white: #dadde1;
$tertiary-red: #ff3434;
$tertiary-white: #ebebeb;
$tertiary-animation-background: #f3f3f3;
$tertiary-orange: #e59335;
$tertiary-blue: #031b4e;
$tertiary-gray-2: #cfd4dd;

//
$box-shadow: rgba(0, 0, 0, 0.06);
$high-opacity-orange: rgba(229, 147, 53, 0.1);
$high-opacity-green: rgba(0, 170, 38, 0.1);
$two-by-two-grid: #f2f5f7;
$fundraising-complete: rgba(100, 66, 207, 0.1);
$closed-stage-box: rgba(29, 95, 195, 0.1);
$tertiary-off-white: #f2f5f7;
$tertiary-gray: #f9fafb;
$tertiary-border-gray: #e5e6eb;

/*
Gradients
*/
$gradient-aqua-1: linear-gradient(46.91deg, #b5d0ea -36.22%, #0f52b9 104.56%);
$gradient-aqua-2: linear-gradient(169.28deg, #dcedfa 5.15%, #aed4f1 95.93%);

/*
Shadows
*/
$shadow-100: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
  0px 4px 4px -2px rgba(24, 39, 75, 0.08);
$shadow-200: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
  0px 8px 8px -4px rgba(24, 39, 75, 0.08);
$shadow-300: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
  0px 8px 16px -6px rgba(24, 39, 75, 0.08);
$shadow-400: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
  0px 8px 24px -4px rgba(24, 39, 75, 0.08);
$shadow-500: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
  0px 10px 32px -4px rgba(24, 39, 75, 0.1);
$shadow-600: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
  0px 12px 42px -4px rgba(24, 39, 75, 0.12);
$shadow-700: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
  0px 14px 64px -4px rgba(24, 39, 75, 0.12);
$shadow-800: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
  0px 18px 88px -4px rgba(24, 39, 75, 0.14);

/*
Border Radiuses
*/

$border-radius-1: 4px;

:export {
  primaryFontFamily: $primary-font-family;
  primaryBlack1: $primary-black-1;
  primaryBlack2: $primary-black-2;
  primaryBlue: $primary-blue;
  secondaryOffWhite: $secondary-off-white;
  secondaryRed: $secondary-red;
  fontSize3: $font-size-3;
}
