@use "../../../../../utilities/scss/variables";
@import "../../../../../utilities/scss/global";

.green-circle {
  width: 8px;
  height: 8px;
  background-color: variables.$secondary-green;
  border-radius: 50%;
}

.yellow-circle {
  width: 8px;
  height: 8px;
  background-color: variables.$secondary-orange;
  border-radius: 50%;
}
.red-circle {
  width: 8px;
  height: 8px;
  background-color: variables.$secondary-red;
  border-radius: 50%;
}

.blue-circle {
  width: 8px;
  height: 8px;
  background-color: variables.$primary-blue;
  border-radius: 50%;
}
