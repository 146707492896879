@use "../../../utilities/scss/variables";
@import "../../../utilities/scss/global";

.container-box {
  background-color: variables.$primary-white;
  border-radius: 6px;
}

.bottom {
  border-bottom: 1px solid variables.$secondary-border;
}
