@use "../../../../../../utilities/scss/variables";
@import "../../../../../../utilities/scss/global";

.icon-container {
  width: 80px;
  height: 80px;
  background-color: variables.$tertiary-gray-2;
  border-radius: 50%;
  span {
    font-family: variables.$primary-font-family;
    font-weight: variables.$font-weight-2;
    font-size: variables.$font-size-9;
    color: variables.$primary-black-2;
  }
}
