@use "../../../../../../utilities/scss/variables";
@import "../../../../../../utilities/scss/global";

.react-pdf__Document {
  height: 500px;
}

.arrow-buttons {
  padding-top: 225px;
}
