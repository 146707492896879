.hover-bg-light:hover {
  background-color: #f8f9fa;
}

.modal {
  &.show {
    display: block;
  }
}

.modal-backdrop {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &.show {
    opacity: 0.5;
  }
}

.modal-dialog-ma {
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.2s ease-in-out;
  max-width: 800px;
  width: 95%;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-content {
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.modal-body {
  max-height: calc(90vh - 180px);
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}

.associations-section {
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
  animation-delay: 0.2s;

  // Add padding only when loading
  &.loading {
    padding: 2rem 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.animate-pulse {
  animation: pulse 1.5s ease-in-out infinite;
}

.skeleton-checkbox {
  width: 16px;
  height: 16px;
  background-color: #e5e7eb;
  border-radius: 4px;
}

.skeleton-avatar {
  background-color: #e5e7eb;
}

.skeleton-text {
  height: 16px;
  background-color: #e5e7eb;
  border-radius: 4px;
}

.cursor-pointer {
  cursor: pointer;
}

.fw-medium {
  margin-right: 2rem;
  min-width: 150px;
}

.text-muted {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
