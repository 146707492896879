@use "./../../../utilities/scss/variables";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.upcoming-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: variables.$high-opacity-orange;
}

.stage-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-3;
  line-height: 14px;
  color: variables.$primary-white !important;
}

.upcoming-stage-text {
  padding: 10px;
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-3;
  color: variables.$tertiary-orange;
}

.currently-fundraising-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: variables.$high-opacity-green;
}

.currently-fundraising-stage-text {
  padding: 10px;
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-3;
  color: variables.$secondary-green;
}

.fundraising-complete-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: variables.$fundraising-complete;
}

.fundraising-complete-stage-text {
  padding: 10px;
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-3;
  color: variables.$secondary-purple;
}

.closed-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: variables.$closed-stage-box;
}

.closed-stage-text {
  padding: 10px;
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-3;
  color: variables.$primary-blue;
}

.past-stage-text {
  padding: 10px;
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-3;
  color: variables.$secondary-red;
} /*# */

.new-upcoming-stage {
  background-color: variables.$secondary-orange;
  color: variables.$primary-white;
}

.new-fundraising-stage {
  background-color: #0091ea;
  color: variables.$primary-white;
}

.new-pending-closing-stage {
  background-color: variables.$secondary-purple;
  color: variables.$primary-white;
}

.new-closed-stage {
  background-color: #00aa26;
  color: variables.$primary-white;
}

.new-past-stage {
  background-color: variables.$secondary-red;
  color: variables.$primary-white;
}

.new-upcoming-stage {
  background-color: #e59335;
  color: variables.$primary-white;
}

.stage-text {
  margin-bottom: "5px";
  margin-top: "5px";
}

.currently-fundraising-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: variables.$high-opacity-green;
}

.currently-fundraising-stage-text {
  padding: 10px;
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-3;
  color: variables.$secondary-green;
}

.fundraising-complete-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: variables.$secondary-purple;
}

.fundraising-complete-stage-text {
  padding: 10px;
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-3;
  color: variables.$secondary-purple;
}

.closed-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: variables.$primary-blue;
}

.closed-stage-text {
  padding: 10px;
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-3;
  color: variables.$primary-blue;
}

.past-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: variables.$secondary-red;
}
