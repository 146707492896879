
.add-account-wizard-container {
  background-color: #ffffff;
  border-radius: 10px;
}
.add-account-outer-column-padded {
  padding-left: 32px;
  padding-top: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
}
.add-account-step-subheader {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #262a3d;
}
.add-account-qualification-subheader {
  max-width: 344px;
  overflow-wrap: break-word;
  font-weight: 500;
  font-size: 12px;
  color: #262a3d;
}
.qualification-question-text {
  font-weight: 400;
  font-size: 12px;
  color: #262a3d;
}
.fade-in-test {
  //   display: inline-block;
  animation: fadeIn linear 400ms;
  -webkit-animation: fadeIn linear 400ms;
  -moz-animation: fadeIn linear 400ms;
  -o-animation: fadeIn linear 400ms;
  -ms-animation: fadeIn linear 400ms;
}

.m-fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}
.radio__input {
  display: none;
}
.radio__radio {
  width: 1.25em;
  height: 1.25em;
  border: 1px solid #dadde1;
  border-radius: 50%;
  padding: 2px;
  box-sizing: border-box;
}
.radio__radio::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-color: #0d47a1;
  border-radius: 50%;
  transform: scale(0);
}
.radio__input:checked + .radio__radio::after {
  transform: scale(1);
  transition: transform 0.15s;
}