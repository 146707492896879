@use "../../../../../utilities/scss/variables";
@import "../../../../../utilities/scss/global";

.two-by-two-grid {
  background-color: variables.$tertiary-off-white;
}

.two-by-two-grid-item {
  padding-top: 10px;
  padding-left: 15px;
  padding-bottom: 10px;
}

.vertical-border:after {
  content: "";
  background: variables.$secondary-border;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 100%;
  width: 1px;
}

.cancel {
  & .body-text-md-medium {
    cursor: pointer;
    opacity: 0.5;
  }
}
