@use "../../../utilities/scss/variables";
@import "../../../utilities/scss/global";

.dropdown-logout-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-size: variables.$font-size-4;
  color: variables.$tertiary-red;
}

.ant-dropdown-menu-item-selected {
  background-color: #e6e8ed !important;
}
