@use "../../../../../../../utilities/scss/variables";
@import "../../../../../../../utilities/scss/global";

.graph-container {
  background-color: variables.$primary-white;
  border-radius: 10px;
}

.portfolio-activity-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-size: variables.$font-size-6;
  font-style: normal;
  padding-top: 25px;
  padding-bottom: 25px;
  color: variables.$primary-black-2;
}

.fund-name-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-style: normal;
  font-size: variables.$font-size-6;
  color: variables.$primary-blue;
}

.bottom-line {
  border-bottom: 1px solid variables.$secondary-border;
}
