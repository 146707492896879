@use "../../../../utilities/scss/variables";
@import "../../../../utilities/scss/global";

.Button {
  background-color: variables.$primary-blue !important;
  border-radius: 4px;
  box-shadow: none !important;
  text-transform: none !important;
  &:hover {
    background-color: variables.$secondary-endeavor !important;
  }
  .body-text-sm-medium {
    color: variables.$primary-white;
  }
  .MuiCircularProgress-root {
    font-size: variables.$font-size-3;
  }
  .MuiCircularProgress-colorPrimary {
    color: variables.$primary-white;
  }
}
