@use "../../../../utilities/scss/variables";
@import "../../../../utilities/scss/global";

.cover-background {
  background-color: variables.$secondary-cloudy-sky;
  z-index: 1;
}

.foreground-content {
  z-index: 3;
  background-color: variables.$primary-white-lilac;
  height: 100vh;
}
