@use "./variables";

/*
This file will include common classes that we will use to define 
*/

h1 {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-10;
  color: variables.$primary-black-2;
  font-style: normal;
}

h2 {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-9;
  color: variables.$primary-black-2;
  font-style: normal;
}

h3 {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-8;
  color: variables.$primary-black-2;
  font-style: normal;
}

h4 {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-7;
  color: variables.$primary-black-2;
  font-style: normal;
}

h5 {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-6;
  color: variables.$primary-black-2;
  font-style: normal;
}

h6 {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-5;
  color: variables.$primary-black-2;
  font-style: normal;
}

.heading-1 {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-10;
  color: variables.$primary-black-2;
  font-style: normal;
}

.heading-2 {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-9;
  color: variables.$primary-black-2;
  font-style: normal;
}

.heading-3 {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-8;
  color: variables.$primary-black-2;
  font-style: normal;
}

.heading-4 {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-7;
  color: variables.$primary-black-2;
  font-style: normal;
}

.heading-5 {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-6;
  color: variables.$primary-black-2;
  font-style: normal;
}

.heading-6 {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-5;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-xl-semibold {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-6;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-xl-medium {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-size: variables.$font-size-6;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-lg-semibold {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-5;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-lg-medium {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-size: variables.$font-size-5;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-lg-regular {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-1;
  font-size: variables.$font-size-5;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-md-bold {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-4;
  font-size: variables.$font-size-4;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-md-semibold {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-4;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-md-medium {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-size: variables.$font-size-4;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-md-regular {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-1;
  font-size: variables.$font-size-4;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-sm-bold {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-4;
  font-size: variables.$font-size-3;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-sm-semibold {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-3;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-sm-medium {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-size: variables.$font-size-3;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-sm-regular {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-1;
  font-size: variables.$font-size-3;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-xs-bold {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-4;
  font-size: variables.$font-size-2;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-xs-semibold {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-3;
  font-size: variables.$font-size-2;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-xs-medium {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-size: variables.$font-size-2;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-xs-regular {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-1;
  font-size: variables.$font-size-2;
  color: variables.$primary-black-2;
  font-style: normal;
}

.body-text-xxs-medium {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-size: variables.$font-size-1;
  color: variables.$primary-black-2;
  font-style: normal;
}
